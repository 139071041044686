
import { defineComponent, ref, onMounted, nextTick } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { PasswordMeterComponent } from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
// import { VueTelInput } from "vue3-tel-input";
// import "vue3-tel-input/dist/vue3-tel-input.css";
export default defineComponent({
  name: "change-password",
  components: {
    Field,
    Form,
    ErrorMessage,
    // VueTelInput,
  },
  setup() {
    const phone = ref(null);
    const formData = ref({
      mobileno: "",
      first_name: "",
      last_name: "",
      property_address: "",
      zip: "",
      state: "",
      city: "",
    });

    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const profile = ref<any>(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const loading = ref<boolean>(false);
    const validNumber = ref<any>();
    const phone_number = ref<any>("");
    const page = ref<string>("");
    const Urlid = ref<any>("");
    const inputOptions = ref<any>({
      styleClasses: "form-control form-control-lg form-control-solid",
    });
    const backurl = ref<any>("");
    // const value = ref('');
    const registration = Yup.object().shape({
      mobileno: Yup.number()
        .typeError("Please Sepacify a number")
        .required()
        .label("Number"),
      first_name: Yup.string().required().label("First Name"),
      last_name: Yup.string().required().label("Last Name"),
      property_address: Yup.mixed().required().label("Property Address"),
      zip: Yup.number()
        .required()
        .typeError("Please specify the number")
        .label("Zip Code"),
      state: Yup.string().required().label("State"),
      city: Yup.string().required().label("City"),
    });

    onMounted(() => {
      const data = route.params.id;
      Urlid.value = data;
      const urlback = router.options.history.state.back;
      backurl.value = urlback;
      getContact(data);
      setCurrentPageBreadcrumbs("Edit Contact", []);
      store.dispatch(Actions.GET_AUTH_USER).then((data) => {
        profile.value = data;
      });
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });
    const getContact = (id) => {
      var request = {
        url: `contact/${id}`,
      };
      store.dispatch(Actions.GET, request).then((data) => {
        if (data) {
          // phone_number.value = data.data.mobileno;

          // console.log(data)
          formData.value = {
            mobileno: data.data.mobileno,
            first_name: data.data.first_name,
            last_name: data.data.last_name,
            property_address: data.data.property_address,
            zip: data.data.zip,
            state: data.data.state,
            city: data.data.city,
          };
          var test = data.data.mobileno;
          phone_number.value = test;
        }
      });
    };

    const testFunction = (event, phoneObject) => {
      // console.log(JSON.stringify(phoneObject));
      if (phoneObject !== undefined) {
        // console.log(event);
        // console.log(phoneObject);
        validNumber.value = phoneObject;
        if (phoneObject.valid) {
          formData.value.mobileno = phoneObject.number;
        } else {
          formData.value.mobileno = "";
        }
        // console.log('dfdfdfd',formData.value.mobileno);
      }
      // console.log();
    };
    const onSubmitRegister = (values, { resetForm }) => {
      submitButton.value!.disabled = true;
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // console.log('values',values);
      var id = route.params.id;
      // console.log('resetForm',resetForm);
      if (values.first_name.trim() == "") {
        Swal.fire({
          text: "Please enter valid First Name!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.last_name.trim() == "") {
        Swal.fire({
          text: "Please enter valid Last Name!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.property_address.trim() == "") {
        Swal.fire({
          text: "Please enter valid Property Address!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.zip.length != 6) {
        Swal.fire({
          text: "Please enter valid Zip Code!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.state.trim() == "") {
        Swal.fire({
          text: "Please enter valid State!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.city.trim() == "") {
        Swal.fire({
          text: "Please enter valid City!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else if (values.mobileno.length != 10) {
        Swal.fire({
          text: "Please enter valid Mobile No!",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      } else {
        loading.value = true;
        var request = {
          url: `contact/${id}`,
          data: {
            mobileno: values.mobileno,
            first_name: values.first_name,
            last_name: values.last_name,
            property_address: values.property_address,
            zip: values.zip,
            state: values.state,
            city: values.city,
          },
        };
        store
          .dispatch(Actions.PUT, request)
          .then((data) => {
            if (data) {
              Swal.fire("Success", "Contact Updated successfully!", "success");
              router.push({ path: backurl.value });
            }
            loading.value = false;
          })
          .catch(() => {
            const [error] = Object.keys(store.getters.getErrors);
            Swal.fire({
              text: store.getters.getErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
      }

      submitButton.value?.removeAttribute("data-kt-indicator");
      submitButton.value!.disabled = false;
    };

    return {
      registration,
      onSubmitRegister,
      submitButton,
      loading,
      phone,
      testFunction,
      validNumber,
      phone_number,
      formData,
      inputOptions,
      page,
      getContact,
      Urlid,
      backurl,
    };
  },
});
